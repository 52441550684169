<template>
  <main-navbar
    v-model="showMenu"
    navbar-menu-classes="navbar-right"
  >
    <div class="navbar-wrapper">
      <a
        class="navbar-brand"
        href="/"
      >{{ env.name }}</a>
    </div>
  </main-navbar>
</template>

<script>
import { MainNavbar } from "~/components/Library/UIComponents";

export default {
  name: "AuthNavbar",
  components: {
    "main-navbar": MainNavbar,
  },
  data() {
    return {
      showMenu: false,
      env: {
        name: process.env.name,
      },
    };
  },
};
</script>
